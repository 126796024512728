import React from 'react';
import lingomdLogo from '../assets/images/lingomd-logo.png';
import lingomdHome from '../assets/images/lingomd/lingomd-home.png';
import lingomdResults from '../assets/images/lingomd/lingomd-results.png';

function LingoMD(){
  return (
    <div>
      <div className='lingomd-background'>
        <div>
          <img src={lingomdLogo} className='lingomd-logo' draggable='false'></img>
        </div>
      </div>
      <div className='project-text'>
        <p><span className='bold'>LingoMD</span> is an application intended to help immigrants and other individuals whose first language is not English to find physicians and other healthcare providers who speak their native language in their area. Using the BetterDoctor and Google Maps REST APIs, LingoMD allows users to search by keyword and geographic location for healthcare providers who speak a second language as well as view the contact information for each of their results.</p>
        <p>This project was created with both front-end user interface design and functionality in mind, starting with sketches, wireframes, and prototyping, and finally built in React with Redux.</p>
        <p><span className='bold small-text'>React/Redux ・ Sketch ・ REST APIs</span></p>
        <p><a href='https://github.com/nikkiawong/lingomd'>View GitHub Repository</a></p>
      </div>
      <div className='lingomd-sc'>
        <div>
          <img src={lingomdHome}></img>
          <p>Start/search view</p>
        </div>
        <div className='lingomd-results'>
          <img src={lingomdResults}></img>
        </div>
        <p>Results/search again view</p>
      </div>
    </div>
  );
}

export default LingoMD;