import React from 'react';
import potterLogo from '../assets/images/potter-logo.png';
import potterWelcome from '../assets/images/potter/potter-welcome.png';
import potterCharacter from '../assets/images/potter/potter-character.png';
import potterAdd from '../assets/images/potter/potter-add.png';
import potterAdmin from '../assets/images/potter/potter-admin.png';

function PotterWiki(){
  return (
    <div>
      <div className='potter-background'>
        <div className='potter-header'>
          <img src={potterLogo}></img>
        </div>
      </div>
      <div className='project-text'>
        <p>Spoiler Free Wiki is a community-based wiki site created to solve the dreaded issue of unwanted spoilers on fan wiki sites. The site has built-in spoiler filters, so users can see as much, or as little, information about their favorite characters and series as they wish.</p>
        <p>As a starting point, Spoiler Free Wiki is currently centered around the <span className='italic'>Harry Potter</span> cinematic universe. Users can view entries about all of the movies as well as a growing list of characters, all customized with spoiler filtering. At the top of each wiki entry page, users have the option to change which movie they're on. They also have the option to add their own entries to the site. These entries are all stored in a Firebase database and appear on the site in real-time.</p>
        <p><span className='bold small-text'>AngularJS ・ Sass ・ Firebase</span></p>
        <p><a href='https://github.com/nikkiawong/potter-wiki'>View GitHub Repository</a></p>
      </div>
      <div className='potter-sc'>
        <div>
          <img src={potterWelcome}></img>
          <p>Welcome page view</p>
        </div>
        <div>
          <img src={potterCharacter}></img>
          <p>Character wiki entry view</p>
        </div>
        <div>
          <img src={potterAdd}></img>
          <p>Add character form</p>
        </div>
        <div>
          <img src={potterAdmin}></img>
          <p>Admin page view</p>
        </div>
      </div>
    </div>
  );
}

export default PotterWiki;