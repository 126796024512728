import React from 'react';
import googleWebLogo from '../assets/images/google-weblogo-white-01.png';
import googleMedicDeck from '../assets/pdfs/GoogleMedic_NW.pdf';

function GoogleMedic(){
  return (
    <div>
      <div className='google-background'>
        <div>
          <img src={googleWebLogo} className='google-weblogo' draggable='false'></img>
        </div>
      </div>
      <div className='project-text'>
        <p>According to US Census data, one out of every 6 people in America speaks a language other than English within the home. 25% of these people do not speak English at all.</p>
        <p><span className='bold'>What is Google Medic?</span></p>
        <p>A medically-minded language translation assistant that combines the instant translation technology of Google Translate and the artificial intelligence and voice recognition capabilities of Google Home to help doctors when communicating with their patients and help them get the care they need, regardless of their linguistic background.</p>
        <div className='video-msg'>
          <p>Watch the case study video to learn more:</p>
        </div>
        <div className='video-container'>
          <iframe className='video' src="https://player.vimeo.com/video/309382124?byline=0&portrait=0" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe><script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <p><span className='bold'>Winner of a 2019 American Advertising Federation Local Gold ADDY Award</span></p>
        <p><span className='bold'>Honorable Mention for UMSOC Praddy 2018 Creative Tech Award</span></p>
        <p><span className='bold small-text'>Illustrator ・ InDesign ・ Copywriting</span></p>
        <p><a href={googleMedicDeck}>View the project deck</a></p>
      </div>
    </div>
  );
}

export default GoogleMedic;

// style="position:absolute;top:0;left:0;width:100%;height:100%;"
