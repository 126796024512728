import React from 'react';
import Menu from './Menu';
import logo from '../assets/images/nw-brand-logo.png';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div class="header">
      <div>
        <Link to='/'>
          <img src={logo} alt='logo' class="logo" draggable='false'></img>
        </Link>
      </div>
      <Menu />
    </div>
  );
}

export default Header;
