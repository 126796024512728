import React from 'react';
import { Link } from 'react-router-dom';

function Gallery(){
  const galleryStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
  return (
    <div style={galleryStyles}>
      <Link to='/lingomd'>
        <div className='lingomd gallery-thumb'>
          <div className='overlay'>
            <div className='textbox'>
              <h4>LingoMD</h4>
              <h5>Front-End Development</h5>
              <p>React / Redux / Sketch / REST APIs</p>
            </div>
          </div>
        </div>
      </Link>
      <Link to='/startup-site'>
        <div className='startup gallery-thumb'>
          <div className='overlay'>
            <div className='textbox'>
              <h4>503Works</h4>
              <h5>Front-End Development</h5>
              <p>Sass / Sketch / JavaScript</p>
            </div>
          </div>
        </div>
      </Link>
      <Link to='/koe-karaoke'>
        <div className='koe gallery-thumb'>
          <div className='overlay'>
            <div className='textbox'>
              <h4>Koe Karaoke</h4>
              <h5>Branding Design</h5>
            </div>
          </div>
        </div>
      </Link>
      <Link to='/potter-wiki'>
        <div className='potter-wiki gallery-thumb'>
          <div className='overlay'>
            <div className='textbox'>
              <h4>Spoiler Free Wiki</h4>
              <h5>Front-End Development</h5>
              <p>AngularJS / Sass / Firebase</p>
            </div>
          </div>
        </div>
      </Link>
      <Link to='/google-medic'>
        <div className='google gallery-thumb'>
          <div className='overlay'>
            <div className='textbox'>
              <h4>Google Medic</h4>
              <h5>Graphic Design / Advertising</h5>
            </div>
          </div>
        </div>
      </Link>
      <Link to='/brickell-city-centre'>
        <div className='brickell gallery-thumb'>
          <div className='overlay'>
            <div className='textbox'>
              <h4>Brickell City Centre</h4>
              <h5>Graphic Design / Advertising</h5>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default Gallery;
