import React, { Component } from 'react';
import './App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import Work from './components/Work';
import About from './components/About';
import Contact from './components/Contact';
import StartupSite from './components/StartupSite';
import PotterWiki from './components/PotterWiki';
import BrickellCityCentre from './components/BrickellCityCentre';
import GoogleMedic from './components/GoogleMedic';
import KoeKaraoke from './components/KoeKaraoke';
import LingoMD from './components/LingoMD';
import Gallery from './components/Gallery';
import { Switch, Route, Router, Link } from 'react-router-dom';

function App() {
  return (
    <div>
      <Header />
      <div>
        <Switch>
          <Route exact path='/' component={Work} />
          <Route exact path='/about' component={About} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/lingomd' component={LingoMD} />
          <Route exact path='/potter-wiki' component={PotterWiki} />
          <Route exact path='/brickell-city-centre' component={BrickellCityCentre} />
          <Route exact path='/google-medic' component={GoogleMedic} />
          <Route exact path='/koe-karaoke' component={KoeKaraoke} />
          <Route exact path='/startup-site' component={StartupSite} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
} 

export default App;