import React from 'react';
import startupLogo from '../assets/images/startup-logo.png';
import startupLanding from '../assets/images/startup-site/startup-landing.png';
import startupAbout from '../assets/images/startup-site/startup-about.png';
import startupAmenities from '../assets/images/startup-site/startup-amenities.png';
import startupAmenities2 from '../assets/images/startup-site/startup-amenities2.png';
import startupJoin from '../assets/images/startup-site/startup-join.png';
import startupJoin2 from '../assets/images/startup-site/startup-join2.png';
import startupContact from '../assets/images/startup-site/startup-contact.png';

function StartupSite(){
  return (
    <div>
      <div className='startup-background'>
        <div className='startup-header'>
          <img src={startupLogo} draggable='false'></img>
        </div>
      </div>
      <div className='project-text'>
        <p>An exercise in the front-end design creative process, following the full process of sketches, low- and high-fidelity wireframing, prototyping, and coding, this project is the official website for a co-working start-up concept of my imagination called 503Works located in the downtown Portland area.</p>
        <p><span className='bold small-text'>HTML ・ Sass ・ Sketch ・ JavaScript</span></p>
        <p><a href='https://github.com/nikkiawong/startup-site'>View GitHub Repository</a></p>
      </div>
      <div className='startup-sc'>
        <div>
          <img src={startupLanding}></img>
          <p>Landing page view</p>
        </div>
        <div className='paired-sc'>
          <div>
            <img src={startupAmenities}></img>
          </div>
          <div>
            <img src={startupAmenities2}></img>
          </div>
        </div>
        <p>Amenities page views</p>
        <div>
          <img src={startupAbout}></img>
          <p>About page view</p>
        </div>
        <div className='paired-sc'>
          <div>
            <img src={startupJoin}></img>
          </div>
          <div>
            <img src={startupJoin2}></img>
          </div>
        </div>
        <p>Join page views</p>
        <div>
          <img src={startupContact}></img>
          <p>Contact page view</p>
        </div>
      </div>
    </div>
  );
}

export default StartupSite;
