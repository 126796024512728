import React from 'react';

function Footer() {
  return (
    <div className='footer'>
      <p>Nikki Wong &copy; 2019</p>
    </div>
  );
}

export default Footer;