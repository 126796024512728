import React from 'react';
import { Link } from 'react-router-dom';
import Gallery from './Gallery';

function Work() {
  const workStyles = {
    marginLeft: '10%',
    marginRight: '10%',
    marginTop: '90px'
  }
  return (
    <div class="work">
      <Gallery />
    </div>
  );
}

export default Work;
