import React from 'react';
import bccLogo from '../assets/images/bcc_lockup.png';
import bccCover from '../assets/images/bcc/bcc-cover.jpg';
import bccExecution1 from '../assets/images/bcc/bcc-execution1.jpg';
import bccExecution2 from '../assets/images/bcc/bcc-execution2.jpg';
import bcc1 from '../assets/images/bcc/bcc-1.jpg';
import bcc2 from '../assets/images/bcc/bcc-2.jpg';
import bcc3 from '../assets/images/bcc/bcc-3.jpg';

function BrickellCityCentre(){
  return (
    <div>
      <div className='bcc-background'>
        <div className='bcc-header'>
          <img src={bccLogo}></img>
        </div>
      </div>
      <div className='project-text'>
        <p><span className='bold'>Client:</span> Brickell City Centre</p>
        <p><span className='bold'>Role:</span> Concept, Execution, Styling, Design/Book Design</p>
        <p><span className='bold'>Date:</span> December 2017</p>
        <p><span className='bold small-text'>Illustrator ・ InDesign ・ Photoshop ・ Art Direction</span></p>
      </div>
      <div className='bcc-photos'>
        <div>
          <img src={bccCover}></img>
        </div>
        <div className='paired-sc'>
          <div>
            <img src={bccExecution1}></img>
          </div>
          <div>
            <img src={bccExecution2}></img>
          </div>
        </div>
        <div>
          <img src={bcc1}></img>
        </div>
        <div>
          <img src={bcc2}></img>
        </div>
        <div>
          <img src={bcc3}></img>
        </div>
      </div>
    </div>
  );
}

export default BrickellCityCentre;