import React from 'react';
import koeLogo from '../assets/images/koe-weblogo-01.png';
import koeMockup from '../assets/images/koe-mockup.jpg';
import koeBrandBook from '../assets/pdfs/Koe_Karaoke_Brand_Book.pdf';

function KoeKaraoke(){
  return (
    <div>
      <div className='koe-background'>
        <div className='koe-header'>
          <img className='koe-logo' src={koeLogo} draggable='false'></img>
        </div>
      </div>
      <div className='project-text'>
        <p>Koe Karaoke is a Japanese-style karaoke club concept based out of Los Angeles, California that I designed a full brand around, including a custom typeface, logo, and various brand elements, such as a handcrafted, photographed piece to be used as wall art.</p>
        <p><span className='bold'>Winner of a 2018 American Advertising Federation Local Gold ADDY Award</span></p>
        <p><span className='bold small-text'>Illustrator ・ InDesign ・ Photoshop</span></p>
        <p><a href={koeBrandBook}>Download the full brand guide</a></p>
      </div>
      <div className='koe-mockup'>
        <img src={koeMockup} draggable='false'></img>
      </div>
    </div>
  );
}

export default KoeKaraoke;