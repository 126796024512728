import React from 'react';

function Contact() {
  return (
    <div className='contact'>
      <h3>Contact</h3>
      <p><a href='mailto:nikki.a.w@comcast.net'>Email</a></p>
      <p><a href='https://github.com/nikkiawong'>GitHub</a></p>
      <p><a href='https://www.linkedin.com/in/nikkiawong/'>LinkedIn</a></p>
    </div>
  );
}

export default Contact;