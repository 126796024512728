import React from 'react';
import { Link } from 'react-router-dom';

function Menu() {
  return (
    <div className='menu-links'>
      <Link to='/'>Work</Link>
      <Link to='/about'>About</Link>
      <Link to='/contact'>Contact</Link>
    </div>
  );
}

export default Menu;
