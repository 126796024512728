import React from 'react';
import profPic from '../assets/images/profilepic.JPG';

function About() {
  return (
    <div className='about'>
      <h3>About</h3>
      <div className='about-info'>
        <div className='about-pic'>
          <img src={profPic} draggable='false'></img>
        </div>
        <div className='about-desc'>
          <p>I've always loved design and creating, which led to my receiving a degree in Communication in Creative Advertising from the University of Miami, as well as technology, which led me to minoring in Interactive Media and becoming a front-end development student at Epicodus in order to become a designer-developer. I am passionate about combining intuitive user experiences with beautiful visual design and enjoy building branding projects. In particular, I love working CSS magic and am eager to continue exploring UI/UX for both mobile and desktop.</p>
        </div>
      </div>
    </div>
  );
}

export default About;